<template>
  <div>
    <loading-component />
  </div>
</template>

<script>

export default {
    components:{
        LoadingComponent: () =>
            import(
                /* webpackChunkName: "LoadingComponent"*/ '@/components/LoadingComponent/LoadingComponent'
            ),
    },
    data() {
        return {
            redirectTo: null,
        };
    },
    beforeMount() {
        this.redirectTo = process.env.VUE_APP_STORE_URL;

        this.redirectToStore();
    },
    methods: {
        redirectToStore() {
            window.location.assign(this.redirectTo);
        },
    },
};
</script>
